import React from 'react';
import Layout from '../components/layout';
import Carousel from '../components/carousel';
import Menu from '../components/menu';
import About from '../components/about';
import Footer from '../components/footer';

const IndexPage = () => (
  <Layout>
    <Carousel />
    <Menu />
    <About />
    <Footer />
  </Layout>
);

export default IndexPage;
