import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import {StaticQuery, graphql} from 'gatsby';
import {createGlobalStyle} from 'styled-components';

import Header from './header';
import {red} from '../modules/colors';

const GlobalStyle = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }

  body {
    font-family: 'Roboto', sans-serif;
    background: white;
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
    transition: color 0.5s ease 0s, filter 0.5s ease 0s;
  }
`;

const Layout = ({children}) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {name: 'description', content: 'Cooking something new for London'},
            {
              name: 'keywords',
              content: `luku, lukulondon, nutella, delicious, foodart, imhungry, hungry, londonfood, uk, streetfood,
                sweet, foodporn, foodart, lukufoods, lukumania, lukusweet, lukulove, lukuyummy, blue, golden, art,
                artisan, springles, londonlife, london2018`
            },
            {name: 'theme-color', content: red}
          ]}
          link={[{
            href: 'https://fonts.googleapis.com/css?family=Roboto',
            ref: 'stylesheet'
          }]}
        >
          <html lang="en" />
        </Helmet>
        <GlobalStyle />
        <Header siteTitle={data.site.siteMetadata.title} />
        <div>
          {children}
        </div>
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
