const red = 'rgb(255, 39, 46)';
const white = 'rgb(255, 255, 255)';
const yellow = 'rgb(255, 238, 74)';
const black = 'rgb(34, 34, 34)';
const lightGray = 'rgb(248, 248, 248)';

export {
  black,
  lightGray,
  red,
  white,
  yellow
};
