import React from 'react';
import styled from 'styled-components';
import {red, white} from '../modules/colors';

const Wrapper = styled.div`
  background-color: ${red};
  padding: 20px;
  color: ${white};
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
`;

const Footer = () => (
  <Wrapper>
    <Container>Luku Foods LTD</Container>
  </Wrapper>
);

export default Footer;
