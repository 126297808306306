import React, {useState} from 'react';
import styled from 'styled-components/macro';
import {setConfig} from 'react-hot-loader';
import {lightGray, white, red} from '../modules/colors';

import SurpriseMountain from './images/surprise-mountain';
import ChocoParty from './images/choco-party';
import SweetSour from './images/sweet-sour';
import CoffeeAddict from './images/coffee-addict';

setConfig({pureSFC: true});

const products = [{
  title: 'The Classic',
  description: 'The classic recipe with high quality honey and cinnamon.',
  image: '',
  ingredients: {fillings: [], toppings: ['Honey', 'Cinnamon']}
}, {
  title: 'Choco Party',
  description: 'Nutella, Salted caramel, Oreo and banana. Mmmm...',
  image: <ChocoParty />,
  ingredients: {fillings: [], toppings: ['Nutella', 'Salted Caramel', 'Oreo', 'Banana']}
}, {
  title: 'Crunchy Bites',
  description: 'Crispy M&Ms covered with Nutella and a surprise guest!',
  image: '',
  ingredients: {fillings: ['M&Ms'], toppings: ['M&Ms', 'Nutella', 'Vanilla cream', 'Ferrero Rocher']}
}, {
  title: 'Coffee Addict',
  description: 'Coffee lovers, something special for you!',
  image: <CoffeeAddict />,
  ingredients: {fillings: ['Coffee cream'], toppings: ['Nutella', 'Wafer curls', 'Oreo']}
}, {
  title: 'Sweet & Sour',
  description: 'If lemon is your thing... and salted caramel for the balance.',
  image: <SweetSour />,
  ingredients: {fillings: ['Lemon cream'], toppings: ['Salted caramel', 'Rainbow candy', 'Cinnamon', 'Trimmed biscuit']}
}, {
  title: 'Surprise Mountain',
  description: 'The mountain of your dreams! With too much of everything!',
  image: <SurpriseMountain />,
  ingredients: {
    fillings: ['Lemon cream', 'Vanilla cream', 'Coffee cream', 'Salted caramel', 'M&Ms'],
    toppings: ['Vanilla cream', 'Nutella', 'Banana', 'M&Ms', 'Biscuits', 'Wafer curls', 'Rainbow candy']
  }
}, {
  title: 'Custom Luku',
  description: 'Make your own Luku! Choose exactly what you like!',
  image: '',
  ingredients: {
    fillings: ['Lemon cream', 'Vanilla cream', 'Coffee cream', 'Salted caramel', 'M&Ms'],
    toppings: ['Nutella', 'M&Ms', 'Salted caramel', 'Honey', 'Banana', 'Oreo', 'Wafer curls', 'Rainbow candy',
      'Biscuit']
  }
}];

const Wrapper = styled.div`
  padding: 40px 0 70px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.h2`
  text-align: center;
  font-size: 36px;
`;

const Subheader = styled.div`
  text-align: center;
  display: block;
  text-transform: uppercase;
  color: ${red};
  font-size: 18px;
  letter-spacing: 4px;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before, &::after {
    content: "";
    background: #707070;
    height: 1px;
    width: 90px;
    display: block;

    @media (max-width: 500px) {
      display: none;
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 0 30px;
`;

const Product = styled.div`
  background-color: ${lightGray};
  max-width: 375px;
  height: 370px;
  margin: 0 20px 40px;
  cursor: pointer;
  position: relative;
  border: 1px solid #EEEEEE;
`;

const Image = styled.div`
  max-width: 375px;
  max-height: 240px;
  overflow: hidden;
`;

const Details = styled.div`
  padding: 10px 35px 10px 35px;
`;

const Title = styled.h3``;

const Description = styled.p``;

const Ingredients = styled.div`
  ${props => (!props.show ? 'display: none;' : 'display: flex;')};
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  max-height: 240px;
  background-color: ${white};
  justify-content: space-evenly;

  @media (max-width: 500px) {
    height: 370px;
    max-height: none;
  }
`;

const IngredientWrapper = styled.div`
  width: 50%;
`;

const IngredientsTitle = styled.h3`
  text-align: center;

  &::after {
    content: "";
    background: ${red};
    height: 1px;
    width: 80%;
    display: block;
    margin: 20px 10%;
  }
`;

const List = styled.div`
  transition: all 0.5s ease;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  /* height: 50%; */
  align-content: space-evenly;
  align-items: center;
`;

const Ingredient = styled.span`
`;

const Menu = () => {
  const initialProductIngredients = products.map(() => false);
  const [productIngredients, setProductIngredients] = useState(initialProductIngredients);

  const onProductClick = index => {
    productIngredients[index] = !productIngredients[index];
    setProductIngredients(productIngredients);
  };

  return (
    <Wrapper id="menu">
      <Header>OUR MENU</Header>
      <Subheader>&nbsp;&nbsp;CREATED TO IMPRESS&nbsp;&nbsp;</Subheader>
      <Container>
        {
          products.map((product, index) => (
            <Product key={product.title} onClick={() => onProductClick(index)}>
              <Image>{product.image}</Image>
              <Details>
                <Title>{product.title}</Title>
                <Description>{product.description}</Description>
              </Details>
              <Ingredients show={productIngredients[index]}>
                {
                  product.ingredients.fillings.length
                    ? (
                      <IngredientWrapper>
                        <IngredientsTitle>
                          {product.ingredients.fillings.length > 1 ? 'Fillings' : 'Filling'}
                        </IngredientsTitle>
                        <List>
                          {
                            product.ingredients.fillings.map(filling => (
                              <Ingredient key={filling}>{filling}</Ingredient>
                            ))
                          }
                        </List>
                      </IngredientWrapper>
                    )
                    : ''
                }
                <IngredientWrapper>
                  <IngredientsTitle>Toppings</IngredientsTitle>
                  <List>
                    {
                      product.ingredients.toppings.map(topping => (
                        <Ingredient key={topping}>{topping}</Ingredient>
                      ))
                    }
                  </List>
                </IngredientWrapper>
              </Ingredients>
            </Product>
          ))
        }
      </Container>
    </Wrapper>
  );
};

export default Menu;
