import React from 'react';
import styled from 'styled-components/macro';
import {black, red, white} from '../modules/colors';

const Wrapper = styled.div`
  background-color: ${black};
  padding: 20px;
  color: ${white};
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  max-width: 1000px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
`;

const Box = styled.div`
  max-width: 33%;
  min-width: 300px;
  a {
    color: ${red};

    &:hover {
      color: ${white};
    }
  }
`;

const Header = styled.h3`

  ::after {
    content: "";
    height: 3px;
    width: 45px;
    background: ${red};
    z-index: 1;
    display: block;
    margin-top: 20px;
  }
`;

const Paragraph = styled.p`

`;

const About = () => (
  <Wrapper id="about">
    <Container>
      <Box>
        <Header>ABOUT</Header>
        <Paragraph>
          We created Luku aiming to offer a traditional Greek dessert in a completely new way.
        </Paragraph>
      </Box>
      <Box>
        <Header>LOCATION</Header>
        <Paragraph>
          Please follow us on&nbsp;
          <a href="https://instagram.com/luku.foods">Instagram</a>
          &nbsp;
          or on&nbsp;
          <a href="https://facebook.com/luku.foods">Facebook</a>
          &nbsp;
          to learn when we open.
        </Paragraph>
      </Box>
      {/* <Box>
        <Header>OPENING HOURS</Header>
      </Box> */}
    </Container>
  </Wrapper>
);

export default About;
