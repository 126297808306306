import React, {useState, useEffect} from 'react';
import {setConfig} from 'react-hot-loader';
import styled from 'styled-components/macro';
import Slide1 from './images/slide1';
import Slide2 from './images/slide2';
import Slide3 from './images/slide3';
import Slide4 from './images/slide4';

setConfig({pureSFC: true});

const slides = [Slide1, Slide2, Slide3, Slide4];

const Wrapper = styled.div`
  width: 100%;
  height: 600px;
  overflow: hidden;

  @media (max-width: 700px) {
    height: 300px;
  }
`;

const Slide = styled.div`
  transition: opacity 0.5s ease;
  opacity: ${props => (props.isActive ? 1 : 0)};
  position: absolute;
  width: 100%;

  > div {
    width: 100%;
    height: 600px;

    @media (max-width: 700px) {
      height: 300px;
    }
  }
`;

const Carousel = () => {
  const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      let nextSlide = activeSlide + 1;
      if (nextSlide > slides.length - 1) {
        nextSlide = 0;
      }
      setActiveSlide(nextSlide);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [activeSlide]);

  return (
    <Wrapper>
      {
        slides.map((slide, index) => (
          <Slide key={index} isActive={activeSlide === index}>
            {slide()}
          </Slide>
        ))
      }
    </Wrapper>
  );
};

export default Carousel;
