import React from 'react';
import {Link} from 'gatsby';
import styled from 'styled-components';
import {black, red, white} from '../modules/colors';

import Instagram from '../images/instagram.svg';
import Facebook from '../images/facebook.svg';

const Wrapper = styled.div`
  background-color: ${white};
  height: 80px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-bottom: 2px solid ${red};
`;

const Logo = styled.div`
  width: 30px;
  height: 30px;
  font-size: 30px;
  font-weight: bold;
  color: ${red};
  width: 80px;
`;

const Menu = styled.div`
  display: flex;

  @media (max-width: 500px) {
    display: none;
  }
`;

const MenuItem = styled.a`
  color: ${black};
  font-size: 18px;
  padding: 0 20px;

  :hover {
    color: ${red};
  }

  @media (max-width: 600px) {
    font-size: 16px;
    padding: 0 4px;
  }
`;

const Social = styled.div`
  a:hover {
    filter: brightness(0);
  }
`;

const SocialItem = styled.img`
  src: url(${props => props.src});
  padding: 0 20px;
  width: 32px;
`;

const Header = () => (
  <Wrapper>
    <Link to="/">
      <Logo>LUKU</Logo>
    </Link>
    <Menu>
      <MenuItem href="/">HOME</MenuItem>
      <MenuItem href="#menu">MENU</MenuItem>
      {/* <MenuItem href="#ingredients">INGREDIENTS</MenuItem> */}
      <MenuItem href="#about">ABOUT</MenuItem>
    </Menu>
    <Social>
      <a href="https://instagram.com/luku.foods">
        <SocialItem title="Instagram" src={Instagram} />
      </a>
      <a href="https://facebook.com/luku.foods">
        <SocialItem title="Facebook" src={Facebook} />
      </a>
    </Social>
  </Wrapper>
);

export default Header;
